import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Dropdown, Toggle as DropdownToggle, Item as DropdownItem, Body as DropdownBody, UIKitProvider } from "./index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h1>
    <h2 {...{
      "id": "dropdown-1"
    }}>{`Dropdown`}</h2>
    <Props of={Dropdown} mdxType="Props" />
    <h2 {...{
      "id": "dropdown-toggle"
    }}>{`Dropdown toggle`}</h2>
    <Props of={DropdownToggle} mdxType="Props" />
    <h2 {...{
      "id": "dropdown-body"
    }}>{`Dropdown body`}</h2>
    <Props of={DropdownBody} mdxType="Props" />
    <p>{`The dropdown component consists of the 4 elements:`}</p>
    <ul>
      <li parentName="ul">{`Dropdown parent component, which wraps the other components`}</li>
      <li parentName="ul">{`Toggle, which is the clickable element that shows or hides the menu`}</li>
      <li parentName="ul">{`Body, which is the actual dropdown menu that is initially hidden`}</li>
      <li parentName="ul">{`Item, which is the menu item within the body`}</li>
    </ul>
    <h3 {...{
      "id": "basic-dropdown"
    }}>{`Basic dropdown`}</h3>
    <UIKitProvider mdxType="UIKitProvider">
    <Dropdown mdxType="Dropdown">
        <DropdownToggle mdxType="DropdownToggle">
            Click me
        </DropdownToggle>
        
        <DropdownBody mdxType="DropdownBody">
            <DropdownItem mdxType="DropdownItem">
                Dropdown item 1
            </DropdownItem>
            <DropdownItem mdxType="DropdownItem">
                Dropdown item 2
            </DropdownItem>
        </DropdownBody>
    </Dropdown>
    </UIKitProvider>
    <h3 {...{
      "id": "show-arrows"
    }}>{`Show arrows`}</h3>
    <UIKitProvider mdxType="UIKitProvider">
    <Dropdown mdxType="Dropdown">
        <DropdownToggle showArrows mdxType="DropdownToggle">
            Click me
        </DropdownToggle>
        
        <DropdownBody mdxType="DropdownBody">
            <DropdownItem mdxType="DropdownItem">
                Dropdown item 1
            </DropdownItem>
            <DropdownItem mdxType="DropdownItem">
                Dropdown item 2
            </DropdownItem>
        </DropdownBody>
    </Dropdown>
    </UIKitProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      